import React from "react"
import { Link } from "gatsby"
import { forString } from "../utils/slugs"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = props => {
  const { pageContext } = props
  const { topCommunes } = pageContext

  return (
    <Layout>
      <SEO title="Etudiant A" />
      <h1>Etudiant A</h1>
      <p>
        <em>Etudiant A</em> est un site web dédié aux étudiants qui font leurs
        études en France! Pour chaque établissement d&apos;enseignement
        supérieur en France, nous proposons les infos étudiantes dans leur
        ville, une liste de liens utiles, ainsi qu&apos;un espace de
        discussions!
      </p>
      <ul>
        {topCommunes.slice(0, 20).map(([commune, count]) => {
          return (
            <li key={commune}>
              <Link to={forString("commune", commune)}>{commune}</Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

IndexPage.propTypes = {
  pageContext: PropTypes.object,
}

export default IndexPage
